import { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { HeaderHome } from "./HeaderHome";
import { Footer } from "./Footer";
import { motion } from "framer-motion";
import logo1 from "assets/images/home/cpb.png";
import logo2 from "assets/images/home/acciona.png";
import logo3 from "assets/images/home/built.png";
import logo4 from "assets/images/home/kapitol.png";
import logo5 from "assets/images/home/ghella.png";
import logo6 from "assets/images/home/McConnell_Dowell.png";
import logo7 from "assets/images/home/delta.png";
import logo8 from "assets/images/home/multiplex.png";
import logo9 from "assets/images/home/webuild.png";
import HeroBg from "assets/images/home/herobg.png";
import testi1 from "assets/images/home/testi1.png";
import testi2 from "assets/images/home/testi2.png";
import testi3 from "assets/images/home/testi3.png";
import arrowbtn from "assets/images/home/arrowBtn.png";
import contractors from "assets/images/home/Contractors.png";
import subcontractors from "assets/images/home/Subcontractors.png";
import Sustainability from "assets/images/home/Sustainability.png";
import Procurement from "assets/images/home/Procurement.png";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import InsightsIcon from "@mui/icons-material/Insights";
import BarChartIcon from "@mui/icons-material/BarChart";
import PolicyIcon from "@mui/icons-material/Policy";
import PlaceIcon from "@mui/icons-material/Place";
import DescriptionIcon from "@mui/icons-material/Description";
import QueryStatsIcon from "@mui/icons-material/QueryStats";

import "./style.css";

const logoSlides = [
  { logo: logo1, name: "CPB" },
  { logo: logo2, name: "ACCIONA" },
  { logo: logo3, name: "BUILT" },
  { logo: logo4, name: "KAPITOL" },
  { logo: logo5, name: "GHELLA", styles: { transform: "scale(2)" } },
  { logo: logo6, name: "MCCONNELL&DOWELL" },
  { logo: logo7, name: "DELTA", styles: { transform: "scale(1.2)" } },
  { logo: logo8, name: "MULTIPLEX", styles: { marginTop: "10px" } },
  { logo: logo9, name: "WEBUILD", styles: { marginBottom: "10px" } },
];

export const Home = () => {
  const settingsLogoslider = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    dots: false,
    infinite: true,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToScroll: 2,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 374,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 2,
        },
      },
    ],
    slidesToScroll: 1,
    slidesToShow: 4,
  };

  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }, []);

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const settingsAboutSlider2 = {
    arrows: false,
    autoplay: false,
    autoplaySpeed: 2000,
    cssEase: "linear",
    dots: false,
    infinite: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          dots: true,
          slidesToScroll: 1,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          dots: true,
          slidesToScroll: 1,
          slidesToShow: 1,
        },
      },
    ],
    slidesToShow: 3,
  };
  const settingsAboutSlider3 = {
    arrows: false,
    autoplay: false,
    autoplaySpeed: 2000,
    cssEase: "linear",
    dots: false,
    infinite: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          dots: true,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          dots: true,
          slidesToScroll: 1,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          dots: true,
          slidesToScroll: 1,
          slidesToShow: 1,
        },
      },
    ],
    slidesToShow: 6,
  };
  const settingsAboutSlider4 = {
    arrows: false,
    autoplay: false,
    autoplaySpeed: 2000,
    cssEase: "linear",
    dots: false,
    infinite: false,
    responsive: [
      {
        breakpoint: 851,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 850,
        settings: {
          dots: true,
          slidesToScroll: 1,
          slidesToShow: 1,
        },
      },
    ],
    slidesToShow: 2,
  };

  return (
    <>
      {/* Header Section  */}
      <HeaderHome />

      {/* Hero Section  */}
      <main className="hero-new">
        <div className="container-new">
          <div className="hero-main">
            <div
              className="hero-content"
              data-aos="fade-right"
              data-aos-duration="2000"
            >
              <motion.div
                initial={{ opacity: 0, translateY: 550 }}
                animate={{ opacity: 1, translateY: 0 }}
                transition={{ damping: 30, stiffness: 150, type: "spring" }}
              >
                <h1>
                  Software built for social procurement and sustainability.
                </h1>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, translateY: 550 }}
                animate={{ opacity: 1, translateY: 0 }}
                transition={{
                  damping: 30,
                  delay: 0.2,
                  stiffness: 150,
                  type: "spring",
                }}
              >
                <p>
                  SocialPro is a powerful cloud-based reporting tool for
                  measuring, managing and reporting social impact and
                  sustainable procurement performance. Take control of your
                  project and maximise impact.
                </p>
              </motion.div>
              <div className="hero-btns">
                <motion.div
                  initial={{ opacity: 0, translateY: 550 }}
                  animate={{ opacity: 1, translateY: 0 }}
                  transition={{
                    damping: 30,
                    delay: 0.4,
                    stiffness: 150,
                    type: "spring",
                  }}
                >
                  <a
                    href="/request-demo"
                    className="discover"
                    style={{
                      alignItems: "center",
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    Request a demo
                    <img src={arrowbtn} alt="arrowbtn" />
                  </a>
                </motion.div>
              </div>
            </div>
            <div className="hero-img">
              <img src={HeroBg} alt="HeroBg" />
            </div>
          </div>
        </div>
      </main>

      {/* Logo section  */}

      <div className="logosection">
        <div className="container-new slider-logo">
          {/* <h3>Trusted by Industry leaders</h3> */}
          <div className="slider-logo-wrap">
            <Slider {...settingsLogoslider}>
              {logoSlides.map((slide) => (
                <div key={slide.name}>
                  <img src={slide.logo} alt={slide.name} style={slide.styles} />
                </div>
              ))}
            </Slider>
          </div>
        </div>
        <div className="container-new about-new-section testimonial">
          <div
            className="about-cards-section "
            style={{ padding: 0, position: "relative" }}
          >
            <Slider {...settingsAboutSlider2}>
              <div className="about-card">
                <p>
                  SocialPro not only simplifies data management but adapts to
                  our unique needs, thanks to an innovative team that truly
                  listens. It’s made reporting at a large scale easy and simple
                  to use for the project and our valued stakeholders. Their
                  commitment to impactful collaboration has made them a vital
                  partner in driving meaningful progress on our project.
                </p>
                <h5 className="personName">Paige Van Every</h5>
                <h6 className="companyName">
                  Social Impact Manager, Suburban Connect
                </h6>
                <div className="card-img">
                  <img src={testi2} alt="Platform" />
                </div>
              </div>
              <div className="about-card">
                <p>
                  Implementing SocialPro streamlines the reporting process at
                  Built, enabling my team to focus on educating project
                  stakeholders and driving social value. As a contractor
                  committed to inclusivity and sustainability, SocialPro's
                  innovative approach and dedication to community impact have
                  made it an essential tool in our operations.
                </p>
                <h5 className="personName">Mariska Price </h5>
                <h6 className="companyName">Social Impact Manager, Built</h6>
                <div className="card-img">
                  <img src={testi1} alt="Platform" />
                </div>
              </div>
              <div className="about-card">
                <p>
                  It has been a priority of ours to harness the power of our
                  social procurement data, and we’re excited by the value we can
                  drive from the visualisations produced by SocialPro's
                  software. The invaluable insights and forecasting capabilities
                  on the platform empowers us to concentrate on strategic
                  resourcing and enhance our capabilities in winning tenders.
                </p>
                <h5 className="personName">Nicholas Newby</h5>
                <h6 className="companyName">
                  Social Procurement & Inclusion Manager, BMD
                </h6>
                <div className="card-img">
                  <img src={testi3} alt="Platform" />
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>

      {/* Report section  */}
      <div className="report-slider">
        <div className="platform-sect">
          <div className="container-new">
            <div className="zigzag">
              <div className="zig-content">
                <div className="zig-inner-content">
                  <h4>Social Procurement Reporting</h4>
                  <h3>
                    Is data collection getting in the way of creating impact?
                  </h3>
                  <p>
                    Track subcontractor reports, maintain an audit trail with
                    supporting documents, and streamline data collection to meet
                    reporting obligations in the most efficient way possible.
                  </p>
                  <div className="btns-wrap hero-btns">
                    {/* <a href="/measure" className="btn btn-grey">
                      Discover more
                    </a> */}
                    <a
                      href="/request-demo"
                      className="discover"
                      style={{
                        alignItems: "center",
                        display: "flex",
                        gap: "10px",
                      }}
                    >
                      Request a demo
                      <img src={arrowbtn} alt="arrowbtn" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="zig-img platform-zigimg">
                <img src={Procurement} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="container-new about-new-section testimonial  report-testi">
          <div
            className="about-cards-section servicecards"
            style={{ padding: 0, position: "relative" }}
          >
            <Slider {...settingsAboutSlider3}>
              <div className="about-card">
                <h4 className="before">5 Hours</h4>
                <h3 className="after">20 Minutes</h3>
                <p>
                  Median time to complete monthly reporting in SocialPro vs
                  spreadsheet returnable systems.
                </p>
              </div>
              <div className="about-card">
                <Diversity3Icon
                  style={{
                    color: "#1479d7",
                    fontSize: "40px",
                    marginBottom: "20px",
                  }}
                />
                <h3>Manage Employees</h3>
                <p>
                  Easily manage your workforce by tracking employee details,
                  social attributes and hours, ensuring accurate reporting and
                  compliance across all projects.
                </p>
              </div>
              <div className="about-card">
                <CurrencyExchangeIcon
                  style={{
                    color: "#1479d7",
                    fontSize: "40px",
                    marginBottom: "20px",
                  }}
                />
                <h3>Submit Spend</h3>
                <p>
                  Submit and verify social spend through our platform, ensuring
                  transparency and accountability of expenditure against local
                  and social objectives.
                </p>
              </div>
              <div className="about-card">
                <InsightsIcon
                  style={{
                    color: "#1479d7",
                    fontSize: "40px",
                    marginBottom: "20px",
                  }}
                />
                <h3>Analytics & Insights</h3>
                <p>
                  Gain valuable insights into project performance with real-time
                  analytics across your supply chain, helping you make informed
                  decisions and achieve your targets efficiently.
                </p>
              </div>
              <div className="about-card">
                <BarChartIcon
                  style={{
                    color: "#1479d7",
                    fontSize: "40px",
                    marginBottom: "20px",
                  }}
                />
                <h3>Project Forecasting</h3>
                <p>
                  Predict and plan for project outcomes with our forecasting
                  tools, allowing you to map out hours, spend, and
                  sustainability usage against your program for better project
                  management.
                </p>
              </div>
              <div className="about-card">
                <PolicyIcon
                  style={{
                    color: "#1479d7",
                    fontSize: "40px",
                    marginBottom: "20px",
                  }}
                />
                <h3>Smart Directory</h3>
                <p>
                  Connect with suppliers that can support your contractual
                  obligations through our Smart Directory, promoting impactful
                  partnerships and fulfilling social and sustainability targets.
                </p>
              </div>
            </Slider>
          </div>
        </div>
        <div className="container-new">
          <div className="zigzag sustain-sect">
            <div className="zig-content">
              <div className="zig-inner-content">
                <h4>Sustainability Reporting</h4>
                <h3>
                  Collaborate and report sustainability across your project.
                </h3>
                <p>
                  Track and report on sustainability metrics like water, waste,
                  fuels, and materials usage, ensuring your projects meet
                  environmental goals.
                </p>
                <div className="btns-wrap hero-btns">
                  {/* <a href="/measure" className="btn btn-grey">
                  Discover more
                </a> */}
                  <a
                    href="/request-demo"
                    className="discover"
                    style={{
                      alignItems: "center",
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    Request a demo
                    <img src={arrowbtn} alt="arrowbtn" />
                  </a>
                </div>
              </div>
            </div>
            <div className="zig-img">
              <img src={Sustainability} alt="" />
            </div>
          </div>
        </div>
        <div className="container-new about-new-section testimonial  report-testi">
          <div
            className="about-cards-section servicecards"
            style={{ padding: 0, position: "relative" }}
          >
            <Slider
              {...settingsAboutSlider2}
              className="servicecards report-testi"
            >
              <div className="about-card">
                <PlaceIcon
                  style={{
                    color: "#1479d7",
                    fontSize: "40px",
                    marginBottom: "20px",
                  }}
                />
                <h3>Local Content</h3>
                <p>
                  Easily report on locally manufactured and supplied content,
                  demonstrating your commitment to supporting local suppliers
                  and meeting project requirements.
                </p>
              </div>
              <div className="about-card">
                <DescriptionIcon
                  style={{
                    color: "#1479d7",
                    fontSize: "40px",
                    marginBottom: "20px",
                  }}
                />
                <h3>Stakeholder Reporting</h3>
                <p>
                  Generate comprehensive and bespoke reports for clients and
                  government stakeholders, showcasing your project's performance
                  and compliance with reporting obligations.
                </p>
              </div>
              <div className="about-card">
                <QueryStatsIcon
                  style={{
                    color: "#1479d7",
                    fontSize: "40px",
                    marginBottom: "20px",
                  }}
                />
                <h3>Company Performance</h3>
                <p>
                  Monitor and assess your company's performance across multiple
                  projects, to measure and report impact created at the
                  organisational level.
                </p>
              </div>
            </Slider>
          </div>
        </div>
        <div className="container-new ">
          <div className="cta-new-section ctasect">
            <h4>Built for construction</h4>
            <h3>Measure, manage, and report in one place.</h3>
            <p>
              SocialPro is purpose-built for main contractors and subcontractors
              to streamline data collection and reporting. Spend more time
              focusing on your projects and less time reporting manually. With
              SocialPro, you can ensure fast and accurate reporting, track
              progress, and achieve your social and sustainability targets
              efficiently.
            </p>
          </div>
        </div>
      </div>

      <div className="blogs-slider">
        <div className="container-new ">
          <div className="two-cols">
            <Slider {...settingsAboutSlider4}>
              <div className="blog">
                <div className="blog-img">
                  <a href="">
                    <div>
                      <img src={contractors} />
                    </div>
                  </a>
                </div>
                <h3>
                  <a href="">Built for main contractors</a>
                </h3>
                <p>
                  SocialPro streamlines data collection for main contractors, by
                  collecting subcontractor reports through the platform, and
                  consolidating data at the project level with a dashboard and
                  various reports and analytics. Change the lens of reporting
                  from manually collating spreadsheets, to reviewing reports and
                  identifying opportunities to maximise compliance and impact.
                  <br></br>
                  <br></br>
                  The platform provides insights into participation, spend, and
                  sustainability metrics, enabling effective project planning
                  and forecasting. Contractors can generate consolidated reports
                  for clients and stakeholders, and SocialPro's Smart Directory
                  helps contractors connect with local and social benefit
                  suppliers.<br></br>
                  <br></br> Overall, SocialPro enhances project management
                  efficiency and fosters meaningful partnerships.
                </p>
              </div>
              <div className="blog">
                <div className="blog-img">
                  <a href="">
                    <div>
                      <img src={subcontractors} />
                    </div>
                  </a>
                </div>
                <h3>
                  <a href="">Built for subcontractors</a>
                </h3>
                <p>
                  SocialPro simplifies reporting for subcontractors, offering a
                  single, user-friendly platform to comply with multiple
                  contractors' project requirements. The reporting function for
                  subcontractors has been designed to be as simple and intuitive
                  as possible in collecting all required information. SocialPro
                  enables accurate tracking of labour hours, spend, and
                  sustainability metrics, ensuring alignment with main
                  contractor targets.<br></br>
                  <br></br>
                  The platform's Smart Directory connects subcontractors with
                  local and social suppliers, to leverage a greater network of
                  partners to support in implementing social and sustainable
                  procurement.<br></br>
                  <br></br>
                  By providing clear performance insights and simplifying
                  reporting, SocialPro allows subcontractors to focus on
                  delivering quality work and achieving impactful results.
                </p>
              </div>
            </Slider>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};
